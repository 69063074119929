import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Video from '../components/video';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Slider from 'react-slick';

// Zoom margin for <Zoom> tags
const customZoomMargin = 150;

function displayImage1(data) {
    if (data.datoCmsWork.image1 !== null) {
        return (
            <div className="sheet__gallery sheet__gallery__right">
                <Zoom
                    zoomMargin={customZoomMargin}
                    image={{
                        src: data.datoCmsWork.image1.fluid.src,
                        alt: data.datoCmsWork.title,
                        className: 'zoom-image-right',
                    }}
                    key={data.datoCmsWork.image1.fluid.src}
                ></Zoom>
            </div>
        );
    }
}

function displayImage2(data) {
    if (data.datoCmsWork.image2 !== null) {
        return (
            <div className="sheet__gallery__center">
                <Zoom
                    zoomMargin={customZoomMargin}
                    image={{
                        src: data.datoCmsWork.image2.fluid.src,
                        alt: data.datoCmsWork.title,
                        className: 'zoom-image-center',
                    }}
                    key={data.datoCmsWork.image1.fluid.src}
                ></Zoom>
                <span className="sheet__gallery_title">
                    {data.datoCmsWork.image2.title}
                </span>
            </div>
        );
    }
}

function displayYoutube(data) {
    if (data.datoCmsWork.youtube !== '') {
        return (
            <Video
                videoSrcURL={data.datoCmsWork.youtube}
                videoTitle={data.datoCmsWork.title}
            />
        );
    } else {
        return (
            <div className="sheet__gallery__center">
                <Zoom
                    zoomMargin={customZoomMargin}
                    image={{
                        src: data.datoCmsWork.coverImageNoYoutube.fluid.src,
                        alt: data.datoCmsWork.title,
                        className: 'zoom-image-right',
                    }}
                    key={data.datoCmsWork.coverImageNoYoutube.fluid.src}
                ></Zoom>
            </div>
        );
    }
}

export default ({ data }) => (
    <Layout>
        <article className="sheet">
            <HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />
            <div className="sheet__inner">
                {/* Title */}
                <h1 className="sheet__title">{data.datoCmsWork.title}</h1>

                {/* Excerpt sentence */}
                <p className="sheet__lead">{data.datoCmsWork.excerpt}</p>

                {/* YouTube Video */}
                {displayYoutube(data)}

                {/* GitHub link */}
                <p className="sheet__title">
                    <a
                        className="sheet_projecturl"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={data.datoCmsWork.projecturl}
                    >
                        Click here to check out the project!
                    </a>
                </p>

                {/* Image 1 picture (use Draw.io's Google Cloud examples) */}
                {displayImage1(data)}

                {/* Introduction content */}
                <div
                    className="sheet__body"
                    dangerouslySetInnerHTML={{
                        __html:
                            data.datoCmsWork.descriptionIntroductionNode
                                .childMarkdownRemark.html,
                    }}
                />

                {/* Functionality content */}
                <div
                    className="sheet__body"
                    dangerouslySetInnerHTML={{
                        __html:
                            data.datoCmsWork.descriptionFunctionalityNode
                                .childMarkdownRemark.html,
                    }}
                />

                {/* Image 2 picture (use Draw.io's Google Cloud examples) */}
                {displayImage2(data)}

                {/* Technologies content */}
                <div
                    className="sheet__body"
                    dangerouslySetInnerHTML={{
                        __html:
                            data.datoCmsWork.descriptionSlideshowNode
                                .childMarkdownRemark.html,
                    }}
                />

                {/* Slider pictures */}
                <Slider
                    className="sheet__body"
                    adaptiveHeight={true}
                    dots={true}
                    infinite={true}
                    slidesToShow={1}
                    autoplay={true}
                >
                    {data.datoCmsWork.slideshow.map(({ fluid }) => (
                        <div>
                            <img
                                className="slider-img"
                                alt={data.datoCmsWork.title}
                                key={fluid.src}
                                src={fluid.src}
                            />
                        </div>
                    ))}
                </Slider>

                {/* Team Members content */}
                <div
                    className="sheet__body"
                    dangerouslySetInnerHTML={{
                        __html:
                            data.datoCmsWork.teamMembersNode.childMarkdownRemark
                                .html,
                    }}
                />
            </div>
        </article>
    </Layout>
);

export const query = graphql`
    query WorkQuery($slug: String!) {
        datoCmsWork(slug: { eq: $slug }) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            isenabled
            title
            excerpt
            youtube
            coverImageNoYoutube {
                fluid(
                    maxWidth: 200
                    imgixParams: { fm: "webp", auto: "compress" }
                ) {
                    src
                }
            }
            slideshow {
                fluid(
                    maxWidth: 200
                    imgixParams: { fm: "webp", auto: "compress" }
                ) {
                    src
                }
            }
            descriptionIntroductionNode {
                childMarkdownRemark {
                    html
                }
            }
            image1 {
                url
                fluid(
                    maxWidth: 600
                    imgixParams: { fm: "webp", auto: "compress" }
                ) {
                    src
                }
                title
            }
            descriptionFunctionalityNode {
                childMarkdownRemark {
                    html
                }
            }
            image2 {
                url
                fluid(
                    maxWidth: 600
                    imgixParams: { fm: "webp", auto: "compress" }
                ) {
                    src
                }
                title
            }
            descriptionSlideshowNode {
                childMarkdownRemark {
                    html
                }
            }
            coverImage {
                url
                fluid(
                    maxWidth: 600
                    imgixParams: { fm: "webp", auto: "compress" }
                ) {
                    ...GatsbyDatoCmsSizes
                }
            }
            projecturl
            teamMembersNode {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`;
