import React from 'react';
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <div className="sheet__video_container">
        <iframe
            className="sheet__video"
            src={videoSrcURL}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
        />
    </div>
);
export default Video;
